<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-tabs
          background-color="transparent"
          centered
          class="mb-5"
          show-arrows
        >
          <v-tab
            v-for="(category, i) in categories"
            :key="i"
            @click="select(category)"
          >
            <span v-text="category.text" />
          </v-tab>
        </v-tabs>

        <v-responsive min-height="350">
          <v-container>
            <transition-group
              tag="v-row"
              name="fade-transition"
            >
              <v-col
                v-for="project in computedProjects"
                :key="project.img"
                cols="12"
                class="gallery-card"
                md="4"
              >
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-card
                      :img="`/static/${project.img}`"
                      class="white--text overflow-hidden"
                      dark
                      height="300"
                      hover
                      :to="project.to"
                    >
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                        >
                          <v-icon large>
                            mdi-link-variant
                          </v-icon>
                        </v-overlay>
                      </v-fade-transition>
                      <v-slide-y-reverse-transition>
                        <v-footer
                          v-if="hover"
                          absolute
                          class="justify-center"
                          height="75"
                        >
                          <div class="heading">
                            {{ project.name }}
                          </div>
                        </v-footer>
                      </v-slide-y-reverse-transition>
                    </v-card>
                  </template>
                </v-hover>
              </v-col>
            </transition-group>
          </v-container>
        </v-responsive>

        <v-fade-transition mode="out-in">
          <v-overlay
            v-if="overlay"
            fixed
            opacity=".9"
          >
            <v-btn
              color="transparent"
              depressed
              fab
              fixed
              large
              right
              top
              @click="overlay = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>

            <v-img
              :src="`/static/${overlay}`"
              width="800"
              max-width="90vw"
            />
          </v-overlay>
        </v-fade-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex'

  export default {
    data: () => ({
      category: null,
      categories: [
        {
          'text': 'WSZYSTKIE PROJEKTY',
          'filter': null
        },
        {
          'text': 'W REALIZACJI',
          'filter': 2
        },
        {
          'text': 'W PRZYGOTOWANIU',
          'filter': 3
        },
        {
          'text': 'INSIDE',
          'filter': 1
        }
      ],
      overlay: false,
      projects: [
        {
          'name': 'Segment szeregowca | Poznań, Grunwald',
          img: 'belwederska.jpg',
          'categories': [2],
          to: '/belwederska-31'
        },
        {
          'name': 'Mieszkanie 36m² - Poznań, ul. Różana',
          img: 'r21-0.jpg',
          'categories': [1],
          to: '/rozana-21'
        },
        {
          'name': 'Nowoczesny dom z bala X1 - 124,5m²',
          img: 'project6a.jpg',
          'categories': [3],
          to: '/dom-z-bala-X1'
        },
        {
          'name': 'Kameralne osiedle Edmundowo Park',
          img: 'ze.jpg',
          'categories': [2],
          to: '/edmundowo-park'
        }
      ]
    }),

    computed: {
      computedProjects () {
        return !this.category
          ? this.projects
          : this.projects.filter(p => p.categories.includes(this.category))
      }
    },

    methods: {
      ...mapMutations(['toggleDrawer']),
      select (category) {
        this.category = category.filter
      }
    }
  }
</script>

<style scoped>
  .gallery-card {
    transform-origin: center center 0;
    transition: .3s cubic-bezier(.25,.8,.50,1);
  }

  .fade-transition-leave,
  .fade-transition-leave-active,
  .fade-transition-leave-to {
    display: none;
  }
</style>
